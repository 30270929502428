import classNames from 'classnames';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import useStickyIntersection from '../../hooks/useStickyIntersection';
import useTranslatedText from '../../hooks/useTranslatedText';
import { BRAND_TAB_DESKTOP_HEIGHT, FEATURE_FLAG_GROUP_COOKIE, STICKY_INTERSECTION_BUFFER } from '../../tools/constants';
import getMyListLink from '../../tools/getMyListLink';
import $window from '../../tools/window';
import BrandTabs from '../BrandTabs';
import BreakpointProvider from '../BreakpointProvider/BreakpointProvider';
import GmsEspotBanner from '../GmsEspotBanner/GmsEspotBanner';
import LargeScreenNavBar from '../LargeScreenNavBar/LargeScreenNavBar';
import MiniGridMarketingPortal from '../MiniGridMarketingPortal/MiniGridMarketingPortal';
import SearchDataProvider from '../SearchBox/SearchDataProvider';
import SkipLink from '../SkipLink/SkipLink';
import StandaloneMfe from '../StandaloneMfe/StandaloneMfe';
import UserPreferences from '../UserPreferences';
import styles from './Header.module.scss';
import MyListButton from './MyListButton';
import MyListNotifications from './MyListNotifications';

const UnifiedHeader = ({
  brand,
  catalogId,
  categoryId,
  country,
  hasDisabledBrandTabs,
  langId,
  pageType,
  storeId,
  storePreview,
  urlRoot,
}) => {
  const targetRef = useRef();
  const isIntersecting = useStickyIntersection(
    targetRef,
    (BRAND_TAB_DESKTOP_HEIGHT + STICKY_INTERSECTION_BUFFER),
  ) !== false;
  const [showNotification, setShowNotification] = useState(false);
  const isCheckout = pageType === 'checkout';
  const myListLink = getMyListLink(storeId, catalogId, langId);
  const openMyList = useTranslatedText('openMyList', { fallback: 'Open My List' });
  useEffect(() => {
    $window.addEventListener('crs:mySaveSuccess', (event) => {
      // leaving this weird logic for now in order not to save
      // current PDP my saves flyout experience
      setShowNotification({ firstTime: event.detail ? event.detail.isFirstTimeSave : true });
      setTimeout(() => {
        setShowNotification(false);
        // 8000ms time needed for animation
      }, 8000);
    });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams($window.location.search);
    const group = params.get('group');
    if (group) {
      Cookies.set(FEATURE_FLAG_GROUP_COOKIE, group, { expires: 1 });
    }
  }, []);

  return (
    <>
      <BreakpointProvider>
        <div id="catalog-mfe-header-backdrop" />
        <div ref={targetRef} />
        <header className={classNames([styles.header], 'scope-1892')}>
          <div className={styles.outerBrandTabs}>
            <SkipLink />
            <div className={
              classNames(
                styles.innerBrandTabs,
                { [styles.disabledBrandTabs]: hasDisabledBrandTabs },
              )
          }
            >
              {!hasDisabledBrandTabs && <BrandTabs />}
              {!isCheckout && (
              <div className={styles.account}>
                <StandaloneMfe componentName="signInBlock" mfeName="customer" />
              </div>
              )}
            </div>
          </div>
          <div className={
          classNames(
            styles.outerNav,
            { [styles.sticky]: !isIntersecting },
          )
}
          >
            <div className={styles.innerNav}>
              <LargeScreenNavBar categoryId={categoryId} storePreview={storePreview} />
              <div className={styles.utilityNav}>
                <SearchDataProvider brand={brand} searchUrl={`${urlRoot}/search`} />
                <UserPreferences defaultCountry={country} parentComponent="header" />
                <MyListButton
                  isActive={showNotification !== false}
                  label={openMyList.value}
                  myListLink={myListLink}
                />
                <StandaloneMfe componentName="miniBag" mfeName="checkout" page="mini" ttl={0} />
                {showNotification
              && (
              <MyListNotifications
                brand={brand}
                createAccountLink={`/shop/AccountRouterView?storeId=${storeId}&isUserLogin=false&catalogId=${catalogId}&langId=${langId}`}
                isFirstTimeSave={showNotification.firstTime}
                myListLink={myListLink}
                signInLink={`/shop/AccountRouterView?storeId=${storeId}&isUserLogin=true&catalogId=${catalogId}&langId=${langId}`}
              />
              )}
              </div>
            </div>
          </div>
          {/*
          This element allows the site to trigger voice over alerts when certain events occur.
          Examples: apply or remove promo, apply or remove reward
          See https://www.w3.org/TR/wai-aria-practices-1.1/examples/alert/index.html
        */}
          <div className="scope-1892">
            <p aria-atomic="true" aria-live="polite" className="screen-reader-text" id="a11y-voiceover-alert" role="status" />
          </div>
          <div className="scope-1892">
            <div aria-live="polite" className="screen-reader-text" id="polite" />
            <div aria-live="assertive" className="screen-reader-text" id="assertive" />
          </div>
        </header>
        <MiniGridMarketingPortal />
        <div className={styles.gmsBanner}>
          <GmsEspotBanner brand={brand} />
        </div>
      </BreakpointProvider>
    </>
  );
};

UnifiedHeader.propTypes = {
  brand: PropTypes.string.isRequired,
  catalogId: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  hasDisabledBrandTabs: PropTypes.bool.isRequired,
  langId: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  storePreview: PropTypes.string.isRequired,
  urlRoot: PropTypes.string.isRequired,
};

export default UnifiedHeader;

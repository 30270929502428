import { Button, Icon } from 'anf-core-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import useIsScrolling from '../../hooks/useIsScrolling';
import useIsSsr from '../../hooks/useIsSsr';
import getPageType from '../../tools/getPageType';
import style from './fakeSearchInputField.module.scss';

const getButtonClassNames = (pageType, isScrollingUp, isSSR) => classNames(
  style.searchInputFieldButton,
  {
    'catalog-mfe-fake-search-sticky': ((pageType === 'homepage' || pageType === 'division') && !isSSR) || (pageType === 'product' && isScrollingUp && !isSSR),
  },
);

const FakeSearchInputField = ({
  handleSearchIconClick = () => {},
  buttonLabel = 'Search',
}) => {
  const isScrollingUp = useIsScrolling(['up']);
  const pageType = getPageType();
  const isSSR = useIsSsr();

  const buttonClassNames = useMemo(
    () => getButtonClassNames(pageType, isScrollingUp, isSSR),
    [pageType, isScrollingUp, isSSR],
  );

  if ((pageType === 'search' || pageType === 'checkout') && !isSSR) return null;

  return (
    <div
      className={style.wrapper}
      data-testid="search-toaster-button"
    >
      <Button
        classList={buttonClassNames}
        onClick={handleSearchIconClick}
        variant="tertiary-light"
      >
        <Icon icon="search" labelText="Search" />
        <span>{buttonLabel}</span>
      </Button>
    </div>
  );
};

FakeSearchInputField.propTypes = {
  handleSearchIconClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
};

export default FakeSearchInputField;

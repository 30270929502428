import { gql, useQuery } from '@apollo/client';
import Accordion from 'anf-core-react/components/Accordion';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import DigitalDataContext from '../../context/digitalData';
import useTranslatedText from '../../hooks/useTranslatedText';
import { DD_SHOW_ANF_NAVIGATION_L3S_TEST } from '../DigitalDataProvider';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import SingleAemEspot from '../SingleAemEspot';
import Text from '../Text/Text';
import style from './SmallScreenNavCategories.module.scss';

// TODO: Query eSpots as part of this query so they are not chained?
const getData = gql`
  query SmallScreenNavCategoriesData($categoryId: String!) {
    category(categoryId: $categoryId) {
      brand
      subCategories(hideCategory: false) {
        categoryId
        espotIdentifier
        hasSpecialNavInteraction
        name
        subCategories(displayTypes: ["regular", "clearance", "featured"], expandByDisplayTypes: ["regular"]) {
          categoryId
          displayType
          name
          url
          subCategories {
            categoryId
            name
            url
          }
        }
      }
    }
  }
`;
const SmallScreenNav = ({ l0CategoryId }) => {
  const { data, loading } = useQuery(getData, {
    // TODO: Revisit if we can use `errorPolicy: 'ignore'` while also caching things correctly; see https://anfcorp.atlassian.net/browse/BROWSE-1417
    variables: { categoryId: l0CategoryId },
  });
  const {
    [DD_SHOW_ANF_NAVIGATION_L3S_TEST]: showL3Categories,
  } = useContext(DigitalDataContext);
  const { value: viewAllText } = useTranslatedText('viewAll', { fallback: 'View All' });
  const [openL1CategoryAccordionId, setOpenL1CategoryAccordionId] = useState(null);
  const [openL2CategoryAccordionId, setOpenL2CategoryAccordionId] = useState(null);
  const eSpot = useCallback((l1Category, idSuffix, removeDuplicateAccordionIds = false) => (
    !!l1Category.espotIdentifier
      && (
        <SingleAemEspot
          espotId={`${l1Category.espotIdentifier}${idSuffix}`}
          removeDuplicateAccordionIds={removeDuplicateAccordionIds}
        />
      )), []);

  if (loading) {
    return <div className={style.messageWrapper}>{LOADING_MESSAGE}</div>;
  }

  // Define displayable L1 categories according to data required for UI
  const displayablel1Categories = data?.category?.subCategories?.filter(
    (cat) => cat && cat.categoryId && cat.name,
  );

  if (!displayablel1Categories || displayablel1Categories.length === 0) {
    return <div className={style.messageWrapper}>{ERROR_MESSAGE}</div>;
  }

  const toggleOpenL1CategoryAccordion = (l1CategoryId) => () => {
    setOpenL1CategoryAccordionId(openL1CategoryAccordionId === l1CategoryId ? null : l1CategoryId);
  };
  const toggleOpenL2CategoryAccordion = (l2CategoryId) => () => {
    setOpenL2CategoryAccordionId(openL2CategoryAccordionId === l2CategoryId ? null : l2CategoryId);
  };
  const l2Categories = (l1Category, displayType, l2CategoriesForDisplayType) => (
    <ul aria-label={`${l1Category.name} ${displayType} categories`}>
      {l2CategoriesForDisplayType.map((l2Category) => {
        // Filter according to data required for UI and appropriate display type
        const l3CategoriesForDisplayType = l2Category.subCategories?.filter(
          (cat) => cat && cat.categoryId && cat.name && cat.url,
        );
        const shouldRenderL3Categories = (data.category.brand !== 'anf' || (showL3Categories ?? false)) && l3CategoriesForDisplayType?.length > 0;

        if (shouldRenderL3Categories) {
          return (
            <li
              key={l2Category.categoryId}
              className={classNames(style.categoryListItem, style.categoryListItemL2)}
            >
              <Accordion
                // Omit brand prop bc it's relevant only if we're setting a sub-brand
                headingLevel={3}
                id={`external-small-screen-nav-l2-accordion-${l2Category.categoryId}`}
                isExpanded={openL2CategoryAccordionId === l2Category.categoryId}
                onClick={toggleOpenL2CategoryAccordion(l2Category.categoryId)}
                title={l2Category.name}
                variant="floating"
              >
                <ul aria-label={`${l2Category.name} ${displayType} categories`} data-level="3">
                  <li
                    data-aui={`small-screen-l3-item-${l2Category.name}`}
                    data-external-small-screen-nav-l2-view-all-list-item-category-id={`${l2Category.categoryId}`}
                  >
                    <a
                      className={classNames(style.categoryLink, style.categoryLinkL3)}
                      href={l2Category.url}
                    >
                      {viewAllText}
                    </a>
                  </li>
                  {
                    l3CategoriesForDisplayType
                      .map((l3Category) => (
                        <li
                          key={l3Category.categoryId}
                          data-aui={`small-screen-l3-item-${l1Category.name.toLowerCase().replaceAll(' ', '-')}-${l3Category.name.toLowerCase().replaceAll(' ', '-')}`}
                          data-external-small-screen-nav-l3-category-list-item-category-id={`${l3Category.categoryId}`}
                        >
                          <a
                            className={classNames(style.categoryLink, style.categoryLinkL3)}
                            href={l3Category.url}
                          >
                            {l3Category.name}
                          </a>
                        </li>
                      ))
                  }
                </ul>
              </Accordion>
            </li>
          );
        }

        return (
          <li
            key={l2Category.categoryId}
            className={classNames(style.categoryListItem, style.categoryListItemL2)}
            data-aui={`small-screen-l2-item-${l1Category.name.toLowerCase().replaceAll(' ', '-')}-${l2Category.name.toLowerCase().replaceAll(' ', '-')}`}
            data-external-small-screen-nav-l2-category-list-item-category-id={l2Category.categoryId}
          >
            <a
              className={classNames(
                style.categoryLink,
                style.categoryLinkL2,
                {
                  [style.categoryLinkClearance]: displayType === 'clearance',
                },
              )}
              href={l2Category.url}
            >
              {l2Category.name}
            </a>
          </li>
        );
      })}
    </ul>
  );

  return (
    <ul data-external-small-screen-nav-l1-category-list-l0-category-id={l0CategoryId} data-level="1">
      {
        displayablel1Categories
          .map((l1Category) => {
            // Define displayable L2 categories according to data required for UI
            const displayableL2Categories = l1Category.subCategories?.filter(
              (cat) => cat && cat.categoryId && cat.name && cat.url,
            );

            return (
              <li
                key={l1Category.categoryId}
                className={classNames(style.categoryListItem, style.categoryListItemL1, 'rs-nav-accordion')}
                data-aui={`small-screen-l1-item-${l1Category.name.toLowerCase().replaceAll(' ', '-')}`}
                data-external-small-screen-nav-l1-category-list-item-category-id={`${l1Category.categoryId}`}
              >
                <Accordion
                  // Omit brand prop bc it's relevant only if we are setting a sub-brand
                  headingLevel={2}
                  id={`external-small-screen-nav-l1-accordion-${l1Category.categoryId}`}
                  isExpanded={openL1CategoryAccordionId === l1Category.categoryId}
                  onClick={toggleOpenL1CategoryAccordion(l1Category.categoryId)}
                  title={(l1Category.hasSpecialNavInteraction && eSpot(l1Category, '-nav-special-interaction-mobile')) || l1Category.name}
                  variant="floating"
                >
                  {eSpot(l1Category, '-nav-mobile', true)}
                  {
                    displayableL2Categories?.length > 0
                      && (
                        <div aria-label={`${l1Category.name} categories`} data-level="2">
                          {
                            ['regular', 'featured', 'clearance'].map((displayType) => {
                              const l2CategoriesForDisplayType = displayableL2Categories
                                .filter((l2Cat) => l2Cat.displayType === displayType);

                              if (
                                displayType === 'regular' && l2CategoriesForDisplayType.length === 0
                              ) {
                                return null;
                              }

                              return (
                                <React.Fragment key={displayType}>
                                  {
                                    (displayType === 'clearance' || displayType === 'featured')
                                    && (
                                      <span className={classNames({ 'screen-reader-text': displayType === 'clearance' })}>
                                        <span
                                          aria-level="3"
                                          className={classNames(style.displayTypeHeader)}
                                          role="heading"
                                        >
                                          <Text pairKey={displayType === 'clearance' ? 'navClearanceGroupHeader' : 'navFeaturedGroupHeader'} />
                                        </span>
                                      </span>
                                    )
                                  }
                                  {eSpot(l1Category, `-cat-column-${displayType}-top-mobile`, true)}
                                  {
                                    l2CategoriesForDisplayType.length > 0
                                      && l2Categories(
                                        l1Category,
                                        displayType,
                                        l2CategoriesForDisplayType,
                                      )
                                  }
                                  {eSpot(l1Category, `-cat-column-${displayType}-bottom-mobile`, true)}
                                </React.Fragment>
                              );
                            })
                          }
                        </div>
                      )
                  }
                </Accordion>
              </li>
            );
          })
      }
    </ul>
  );
};

SmallScreenNav.propTypes = {
  l0CategoryId: PropTypes.string.isRequired,
};

export default SmallScreenNav;

import { TabPanel } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import useDesignSystemInit from '../../hooks/useDesignSystemInit';
import useFetchMySaves from '../../hooks/useFetchMySaves';
import ProductCard from '../ProductCard';
import imageSetType from '../types/imageSetType';
import priceType from '../types/priceType';
import { CLICK_BEHAVIORS } from '../types/productCardClickBehaviorType';
import promoMessageType from '../types/promoMessageType';
import style from './CategoryRecommendations.module.scss';

const sliderSettings = JSON.stringify({
  arrows: false,
  dots: true,
  focusable: false,
  /**
   * Disable infinite scrolling because cloning of slide elements breaks React event listeners
   * See https://github.com/kenwheeler/slick/issues/940
   */
  infinite: false,
  mobileFirst: true,
  rows: 2,
  slidesPerRow: 2,
  responsive: [
    {
      breakpoint: 825,
      settings: {
        arrows: true,
        dots: false,
        rows: 1,
        slidesPerRow: 1,
        slidesToShow: 4,
      },
    },
  ],
});

function CategoryRecommendationsPanel({ placement, selectedTab }) {
  const productListRef = useRef();
  const {
    id = '', name = '', products, treatments,
  } = placement;
  const tabId = `${id}-tab`;
  const isActive = selectedTab === id;

  const { mySavesItems, refetch } = useFetchMySaves();

  // (Re-)initialize Deisgn System when element is rendered or placement/slides data changes
  useDesignSystemInit(productListRef, [placement]);

  /**
   * Refresh slider positioning/sizing when panel is active/visible to reveal slider.
   * Check that slider has already initialized because it is not guaranteed due to apparent race
   * condition with useDesignSystemInit; if slider is not initialized, we don't have to worry about
   * calling this method since slider initialization via useDesignSystemInit will set up the
   * appropriate positioning/sizing.
   * See https://github.com/kenwheeler/slick/issues/341
   */
  useEffect(() => {
    if (isActive && window.jQuery && productListRef.current?.classList.contains('slick-initialized')) {
      window.jQuery(productListRef.current).slick('setPosition');
    }
  }, [isActive]);

  return (
    <TabPanel
      ariaLabelledBy={tabId}
      id={tabId}
      isVisible={isActive}
    >
      <ul
        ref={productListRef}
        // Non-module classes required for Design System usage
        className={`${style.productList} slider js-slider`}
        // data-* attributes necessary for Design System usage
        data-load="auto"
        data-slider={sliderSettings}
      >
        {products.map((product, idx) => {
          const placementObjForAnalytics = { id, name, treatments };
          const algonomyAnalyticsObj = {
            placementObj: placementObjForAnalytics,
            productIndex: idx,
          };

          return (
            <ProductCard
              key={product.id}
              algonomyOnClickAnalytics={algonomyAnalyticsObj}
              className="slide"
              clickBehavior={CLICK_BEHAVIORS.quickview}
              deferImageLoad={false}
              mySavesItems={mySavesItems}
              product={{
                ...product,
                memberPrice: product.memberPriceObject,
                price: product.priceObject,
                // swatchList data not yet provided by xp-utilities package
                swatchList: [],
              }}
              refetch={refetch}
            />
          );
        })}
      </ul>
    </TabPanel>
  );
}

export default CategoryRecommendationsPanel;

CategoryRecommendationsPanel.propTypes = {
  placement: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.shape({
      badges: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        theme: PropTypes.string,
      })),
      collection: PropTypes.string,
      defaultSwatchSequence: PropTypes.string,
      departmentName: PropTypes.string,
      id: PropTypes.string.isRequired,
      imageSet: imageSetType.isRequired,
      kic: PropTypes.string,
      memberPriceObject: priceType,
      name: PropTypes.string.isRequired,
      partNumber: PropTypes.string,
      priceObject: priceType.isRequired,
      productPageUrl: PropTypes.string,
      promoMessaging: promoMessageType,
      shortDescriptors: PropTypes.arrayOf(PropTypes.string),
    })).isRequired,
    treatments: PropTypes.arrayOf(PropTypes.shape({
      control: PropTypes.bool,
      testId: PropTypes.number,
      testType: PropTypes.string,
      treatmentId: PropTypes.string,
      treatmentName: PropTypes.string,
    })).isRequired,
  }).isRequired,
  selectedTab: PropTypes.string,
};

CategoryRecommendationsPanel.defaultProps = {
  selectedTab: '',
};
